@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-frame-info {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    font-size: 0.8rem;
    opacity: 0.8;
  }

  &-info-time {
    // border-bottom: 1px dashed $primary-color;
    cursor: pointer;
  }
}
