@import '../../variables.scss';

$prefix: 'input-number';

.#{$prefix} {
  & {
    flex: 1;
    width: 25px;
    margin-right: 0.3rem;
    padding: 0 0.3rem;
    border: 1px solid $component-background-color-light;
    background-color: $component-background-color-light;
    transition: all 0.3s;
    outline: none;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-color: $primary-color;
      cursor: row-resize;
    }

    &:focus {
      border-color: $primary-color;
      box-shadow: 0 0 0 2px $primary-color-tint;
    }
  }
}