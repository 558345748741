@import '../variables.scss';

$prefix: 'viewer2';

// Local variables
$viewer-border: 1px solid $component-background-color-light;

// Mixin: fit icon
@mixin icon-base {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font: {
    size: 20px;
    style: normal;
  }
  text: {
    align: center;
    transform: none;
  }
  vertical-align: -0.125em;
  transition: 0.2s;
}

@mixin fit-base($position) {
  $dimension-pcnt: 40%;
  $offset: 12%;
  $border: 2px solid $text-color;

  content: '';
  position: absolute;
  display: inline-block;
  height: $dimension-pcnt;
  width: $dimension-pcnt;

  @if $position == tl {
    top: $offset;
    left: $offset;
    border: $border {
      bottom: none;
      right: none;
    }
  } @else if $position == br {
    bottom: $offset;
    right: $offset;
    border: $border {
      top: none;
      left: none;
    }
  }
}

.#{$prefix} {
  & {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  // Up panel
  &-up {
    flex: 1;
    display: flex;
    min-height: 0;
  }

  &-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;
    border: {
      top: $viewer-border;
      bottom: $viewer-border;
    }
    background-color: $component-background-color;

    &-top,
    &-bottom {
      display: flex;
      flex-direction: column;
    }

    &-btn {
      display: inline-block;
      padding: 0.2rem;
    }

    &-input {
      display: flex;
      margin: 0.3rem 0;
      background-color: $component-background-color-light;
      font-size: 0.7rem;

      &-suffix {
        padding: 2px 2px 2px 1px;
        user-select: none;
      }
    }
  }

  &-canvas {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &-wrapper {
      flex: 1;
    }

    &-ctx {
      position: absolute;
      padding: 0.3rem;
      display: flex;
      align-items: center;
      background-color: $component-background-color-light;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      user-select: none;
      font-size: 0;
      z-index: 1;

      &-select {
        display: flex;
        min-width: 7.6rem;
      }

      &-divider {
        height: 1rem;
        margin: 0 0.3rem;
        border-left: 1px solid #4a4a4a;
      }
    }
  }

  // Right Tabs
  &-tabs {
    display: flex;
    width: 230px;
    background-color: $component-background-color;
  }

  &-labels {
    &-collection {
      flex: 1;
      padding: 0;
      margin: 0;
      overflow: auto;

      &-item {
        padding: 0.3rem 0.5rem;
        margin-bottom: 0.5rem;
        background-color: $component-background-color-light;
        transition: all 0.2s;
        border: {
          width: 1px;
          style: solid;
          color: $component-background-color-light;
        }
        cursor: pointer;

        &.selected {
          background-color: $row-highlight-background-color;
          border-color: $row-highlight-background-color;
        }
      }

      &-empty {
        margin-top: 1rem;
        color: $text-color-secondary;
        text-align: center;
        user-select: none;
      }
    }
  }

  &-label {
    &-row {
      display: flex;
      align-items: center;
    }

    &-type {
      flex: 1;
      align-items: center;
    }

    &-btn {
      @include button-base;
      color: $text-color-secondary;

      &:hover {
        color: $error-color;
      }

      &:active {
        background-color: $row-highlight-background-color;
      }
    }

    &-tag {
      position: relative;
      display: inline-block;
      margin-left: 0.6rem;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: $error-color;
      vertical-align: middle;
      user-select: none;

      &::before {
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        border: {
          width: 1px;
          style: solid;
          color: $error-color;
        }
        border-radius: 50%;
      }
    }
  }

  // Bottom panel
  &-bottom {
    display: flex;
    align-items: center;
    padding: 0.3rem 2rem 0.5rem;
    background-color: $component-background-color;
  }

  &-control {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;

    &-slider {
      flex: 1;
    }

    &-frames {
      padding-left: 0.5rem;
    }
  }

  &-actions {
    display: flex;
    padding: 0 0.5rem;
    text-align: center;

    &-btn {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &-btn {
    @include button-base;
    color: $text-color-secondary;
    font-size: $font-size-de;

    &:hover {
      color: $text-color;
      background-color: $row-highlight-background-color;
    }

    &:active {
      background-color: $row-highlight-background-color;
    }

    &.disable {
      pointer-events: none;
    }
  }

  &-icon {
    &-fit {
      @include icon-base;

      &::before {
        @include fit-base(tl);
      }

      &::after {
        @include fit-base(br);
      }
    }

    &-center {
      @include icon-base;

      &::before {
        $dimension-pcnt: 80%;
        $offset: (100% - $dimension-pcnt) / 2;

        content: '';
        position: absolute;
        top: $offset;
        left: $offset;
        width: $dimension-pcnt;
        height: $dimension-pcnt;
        border: 2px solid $text-color;
      }

      &::after {
        $dimension-pcnt: 25%;

        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: $dimension-pcnt;
        width: $dimension-pcnt;
        background-color: $text-color;
        transform: translate(-50%, -50%);
      }
    }

    &-fail {
      @include icon-base;

      height: 1rem;
      width: 1rem;
      font-size: 1rem;
      text-align: center;
      border: 1px solid;
      border-radius: 50%;

      &::before {
        content: 'F';
        position: absolute;
        top: 50%;
        left: 4px;
        font-size: 0.7rem;
        font-weight: bold;
      }

      &.active {
        color: $error-color;
      }
    }
  }
}

.viewer2 .ant-select {
  flex: 1;
}
