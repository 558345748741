@import '../variables.scss';

$prefix: 'na';

.#{$prefix} {
  & {
    flex: 1;
    padding: 2rem;
    text-align: center;
  }

  &-content {
    display: inline-block;
    margin-left: 0.5rem;
    color: $text-color;
    font-size: $font-size-de;
    font-weight: bold;
  }
}
