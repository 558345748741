@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-addList-row {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 40px;
    z-index: 1;
  }

  &-lists {
    padding: 0;
    margin: 0;
    li{
      position: relative;
      width: 150px;
      text-align: left;
      line-height: 18px;
      list-style-type: none;
      margin:0px 5px;
      font-size: 13px;
      background:#000;
      padding: 5px;
      cursor: pointer;
      span{
        width: 18px;
        height: 18px;
        margin-right:6px;
        float: left;
      }
    }
    li:hover{
      background: #666;
    }
    .add-active{
      background: #333;
    }
  }
  &-addRect-row {
    display: flex;
    position: relative;
  }

  &-rect-lists {
    padding: 0;
    margin: 0;
    li{
      position: relative;
      list-style-type: none;
      width: 30px;
      height: 30px;
      line-height: 24px;
      margin-bottom:5px;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      font-size: 22px;
      margin-left: 2px;
      border: 2px solid #202020;
      box-sizing:border-box
    }
    li:hover{
      background: #4a4a4a;
      // border: 3px solid #D4AC02;
      // background: rgba($color: #D4AC02, $alpha: 0.2);
    }
    .add-active-car{
      border: 2px solid #3498DB;
      background: rgba($color: #3498DB, $alpha: 0.2);
    }
    .add-active-cone{
      border: 2px solid #02fde3;
      background: rgba($color: #02fde3, $alpha: 0.2);
    }
    .add-active-pedestrian{
      border: 2px solid #148F77;
      background: rgba($color: #148F77, $alpha: 0.2);
    }
    .add-active-truck{
      border: 2px solid #FF1493;
      background: rgba($color: #FF1493, $alpha: 0.2);
    }
    .add-active-bicycle{
      border: 2px solid #fd8d02;
      background: rgba($color: #fd8d02, $alpha: 0.2);
    }
    .add-active-cyclist{
      border: 2px solid #D4AC0D;
      background: rgba($color: #D4AC0D, $alpha: 0.2);
    }
    .add-active-bus{
      border: 2px solid #FB9322;
      background: rgba($color: #FB9322, $alpha: 0.2);
    }
    .add-active-more{
      border: 2px solid #fff;
      background: rgba($color: #fff, $alpha: 0.2);
    }
  }
}

