@import '../../../variables.scss';

$prefix: 'labels';

.#{$prefix} {
  &-addList-row {
    display: flex;
    position: absolute;
    bottom: 3rem;
    right: 50%;
    z-index: 1;
  }

  &-lists {
    padding: 0;
    margin: 0;
    li{
      position: relative;
      width: 150px;
      text-align: left;
      line-height: 18px;
      list-style-type: none;
      margin:0px 5px;
      font-size: 13px;
      background:#000;
      padding: 5px;
      cursor: pointer;
      span{
        width: 18px;
        height: 18px;
        margin-right:6px;
        float: left;
      }
    }
    li:hover{
      background: #666;
    }
    .add-active{
      background: #333;
    }
  }
}
