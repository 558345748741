@import '../../../variables.scss';

$prefix: 'viewer2';

.#{$prefix} {
 &-labels-right{
   li {
    line-height: 25px;
   }
 }
}
