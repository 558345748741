@import '../../variables.scss';

$prefix: 'loading-cover';

.#{$prefix} {
  & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 100vh;
    background-color: $body-background-color;
    text-align: center;
    opacity: 0.6;
    z-index: 5;

    &.finished {
      max-height: 0;
      opacity: 0;
      transition: max-height 0s ease 0.3s, opacity 0.3s;
    }
  }
}
