// vendors
@import '~include-media/dist/_include-media.scss';

// colors
$primary-color: #138d75;
$primary-color-tint: transparentize(mix(black, $primary-color, 5%), 0.8);
$text-color: #e5e5e5;
$text-color-secondary: #797979;
$text-color-title: #808080;
$body-background-color: #151515;
$component-background-color: #202020;
$component-background-color-light: #333333;
$row-hover-background-color: #4a4a4a;
$row-highlight-background-color: #676565;
$canvas-background-color: #161616;
$input-warning-color: #b2b2b2;
$error-color: #be3829;

// font0-size
$font-size-s: 0.8rem;
$font-size-xs: 0.7rem;
$font-size-de: 1rem;
$font-size-l: 1.2rem;

// break points: overide default breakpoints of 'include-media'
$breakpoints: (
  's': 420px,
  'm': 768px,
  'l': 1024px
);

@mixin button-base {
  display: inline-block;
  margin: 0 1px;
  padding: 3px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  text-align: center;
  line-height: 100%;
  vertical-align: 10%;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  transition: all 0.2s;
}
