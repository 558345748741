@import '../variables.scss';

$prefix: 'groups';

.#{$prefix} {
  & {
    flex: 1;
    overflow: auto;
  }

  &-table-panel {
    display: flex;
    margin: 2rem;
  }

  &-empty {
    padding: 2rem;
    color: $text-color-secondary;
    user-select: none;
  }

  &-table-wrapper {
    flex: 1;
  }

  &-delete-button {
    display: inline-block;
    margin-left: 1rem;
    font-size: 0.8rem;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
      color: #cb4335;
    }
  }

  &-checkboxes-row {
    margin-bottom: 0.5rem;
  }
}
