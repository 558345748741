@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-shortcuts-row {
    display: flex;
    margin-bottom: 0.3rem;
  }

  &-shortcuts-mark-row-up {
    padding-top: 1rem;
  }

  &-shortcuts-mark-row-down {
    padding-bottom: 1rem;
  }

  &-shortcuts-title {
    display: inline-block;
    margin-bottom: 0.3rem;
    color: $text-color-title;
  }

  &-shortcuts-comment {
    display: inline-block;
    color: $text-color-title;
    font-size: 0.7rem;
  }

  &-shortcuts-command {
    display: inline-block;
    width: 7rem;
  }

  &-shortcuts-keybinding {
    flex: 1;
    text-align: center;
  }

  &-shortcuts-key {
    position: relative;
    display: inline-block;
    min-width: 24px;
    margin-right: 0.5rem;
    padding: 0 0.3rem;
    background-color: $component-background-color-light;
    text-align: center;
    user-select: none;
  }

  &-shortcuts-key-ph {
    display: inline-block;
    min-width: 24px;
    margin-right: 0.5rem;
    user-select: none;
  }

  &-shortcuts-key-ext {
    display: inline-block;
    margin-right: 0.5rem;
    color: $text-color-title;
    user-select: none;
  }

  &-shortcuts-key-mark-up {
    position: absolute;
    top: -70%;
    left: 50%;
    transform: translate(-50%);
    font-size: 0.5rem;
    color: $text-color-title;
    white-space: nowrap;
    user-select: none;
  }

  &-shortcuts-key-mark-down {
    position: absolute;
    left: 50%;
    bottom: -70%;
    transform: translate(-50%);
    font-size: 0.5rem;
    color: $text-color-title;
    white-space: nowrap;
    user-select: none;
  }

  &-shortcuts-key-gutter {
    display: inline-block;
    margin-right: 0.5rem;
    user-select: none;
  }
}
