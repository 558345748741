$prefix: 'label-type';

.#{$prefix} {
  & {
    display: inline-block;
    margin-right: 0.5rem;
    color: transparent;
    line-height: 90%;
    user-select: none;
  }
}
