@import '../variables.scss';

$prefix: 'views';

.#{$prefix} {
  & {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  //* upper panel
  &-top-panel {
    position: relative;
    flex: 1;
    display: flex;
    min-height: 0; // An important setting, see https://blog.csdn.net/qq_35038331/article/details/73527395
  }

  //* left panel
  &-left-panel {
    position: relative;
    display: flex;
    flex: 1;
  }

  &-aux {
    position: absolute;
    top: 0;
    right: 0.5rem;
    bottom: 0;
    left: 66%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding: 0.5rem 0;
    z-index: 1;
  }

  &-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $component-background-color;
    border: 3px solid $component-background-color;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-image {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    margin-bottom: 0.5rem;
    border: 3px solid $component-background-color;
    background-color: $canvas-background-color;
    max-width: 30%;
    z-index: 1;

    img {
      width: 100%;
      border: 2px solid $component-background-color;
    }

    &-missing {
      margin: 0.4rem;
      padding: 0.2rem 0.5rem;
      font-size: $font-size-s;
      background-color: transparentize($component-background-color, 0.5);
    }
  }

  &-box-bar {
    font-size: 0;
    background-color: $component-background-color;
  }

  &-main {
    position: relative;
    flex: 1;
    overflow: hidden;
  }

  //* right panel
  &-right-panel {
    display: flex;
    width: 320px;
  }

  &-panel-inner {
    flex: 1;
    display: flex;
    background-color: $component-background-color;
  }

  //** views
  &-side,
  &-top {
    position: relative;
    flex: 1;
    min-height: 0;
  }

  &-name {
    flex: none;
    position: relative;
    display: inline-block;
    padding: 2px 6px 2px 10px;
    background-color: $canvas-background-color;
    font-size: $font-size-xs;
    font-weight: bold;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 100%;
      border-width: 10px 8px;
      border-style: solid;
      border-color: transparent transparent $canvas-background-color
        $canvas-background-color;
    }
  }

  //** labels
  &-labels-collection {
    height: 30vh;
    margin-bottom: 1.5rem;
    background-color: $component-background-color-light;
    overflow: auto;
  }

  &-collection-row {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.5rem;
    transition: all 0.1s;
    cursor: pointer;

    &:hover,
    &.highlight:hover {
      background-color: $row-hover-background-color;
    }

    &.highlight {
      background-color: $row-highlight-background-color;
    }
  }

  &-collection-empty {
    margin-top: 10vh;
    color: $text-color-secondary;
    text-align: center;
    user-select: none;
  }

  //* lower panel
  &-bottom-panel {
    display: flex;
    align-items: center;
    padding: 0.3rem 2rem 0.5rem;
    background-color: $component-background-color;
  }

  &-frames-slider {
    flex: 3;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }

  &-slider-bar {
    flex: 1;
  }

  &-slider-input {
    display: flex;
    align-items: center;

    &-frames {
      padding-left: 0.5rem;
    }
  }

  &-frames-actions {
    display: flex;
    padding: 0 0.5rem;
    text-align: center;
  }

  &-frames-action {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  & canvas {
    height: 100%;
    width: 100%;
  }

  &-label {
    color: $text-color;
    font-size: 0.7rem;
    font-weight: bold;
    padding: 2px 4px;
  }

  // Settings
  &-setting-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.3rem;
  }

  &-setting-title {
    color: $text-color-title;
  }

  &-setting-head {
    width: 6rem;
  }

  &-setting-control {
    flex: 1;
  }

  //* examiner actions
  &-exam-row {
    display: flex;
    margin-bottom: 0.3rem;
  }

  &-exam-head {
    display: inline-block;
    width: 5rem;
  }
}

.views-panel-inner .ant-input {
  flex: 1;
}

.views-panel-inner .ant-select {
  flex: 1;
}
