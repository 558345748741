@import '../../variables.scss';

$prefix: 'hover-panel';

.#{$prefix} {
  & {
    position: absolute;
    top: 0.5rem;
    padding: 0 1rem 1rem 1rem;
    transition: all 0.2s;
    opacity: 0.4;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  &-inner {
    padding: 0.3rem 0.3rem;
    background-color: $component-background-color;
  }
}
