@import '../variables.scss';

$prefix: 'statistics';

.#{$prefix} {
  & {
    width: 100%;
    min-height: 0;
    padding: 2rem;
    overflow: auto;
  }

  &-left-panel {
    width:28%;
    float: left;
  }

  &-filter-row {
    margin-bottom: 1rem;

    &.dates {
      display: grid;
      grid-template-columns: 28% auto;
      gap: 1rem;
    }
  }

  &-right-panel {
    width: 70%;
    float: left;
    margin-left: 2%;
    overflow: auto;
  }
  &-table {
    width: 100%;
    min-height: 0;
    padding: 1rem;
    float: left;
    margin-top: 1rem;
    background-color: $component-background-color;
    .taskDetails span{
      margin-right: 0.5rem;
    }
  } 
  &-box {
    padding:1rem 0 0 2rem
  }
  &-stats {
    background-color: $component-background-color;
  }
  &-note {
    margin-top: -5rem;
    padding-bottom: 0.5rem;
    margin-left: 1rem;
    line-height: 0.5rem;
    color: $primary-color;
  }

  &-stats-block {
    margin-bottom: 1.5rem;
  }

  &-stat-row {
    &.filter {
      display: inline-grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      gap: 0.5rem 1rem;
    }

    &.stats {
      display: inline-grid;
      grid-template-columns: repeat(3, 8rem);
      gap: 1rem;
    }
  }

  &-stat-title {
    margin-bottom: 1rem;
    color: $text-color-title;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &-stat-subtitle {
    margin-bottom: 0;
    color: $text-color-title;
    font-weight: bold;
    font-size: 0.8rem;
  }
}

.ant-picker-content th, .ant-picker-content td:hover div{
  background: #4a4a4a!important;
}
.ant-picker-time-panel-column > li:hover div{
  background: #4a4a4a!important;
}
