@import '../../../variables.scss';

$prefix: 'viewer2';

.#{$prefix} {
  &-frame-info {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    padding: 0.4rem 0.5rem;
    background-color: transparentize($component-background-color, 0.4);
    font-size: 0.8rem;
  }

  &-info-time {
    cursor: pointer;
  }
}
