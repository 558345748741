@import '../variables.scss';

$prefix: 'footer';

.#{$prefix} {
  & {
    position: absolute;
    bottom: 10px;
    right: 100px;
    margin: auto;
  }

  &-font {
    color: #999;
    font: 12px;
    text-align: center;
  }
}