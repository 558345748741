@import '../variables.scss';

$prefix: 'bags';

.#{$prefix} {
  & {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }

  &-table-panel {
    margin: 2rem 2rem;
    overflow: auto;
  }

  &-empty {
    padding: 2rem;
    color: $text-color-secondary;
    user-select: none;
  }

  &-modal-tail {
    margin: 1.5rem 0;
  }

  &-delete-button {
    display: inline-block;
    margin-left: 1rem;
    font-size: 0.8rem;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
      color: #cb4335;
    }
  }
}
