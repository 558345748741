@import '../../variables.scss';

$prefix: 'header';

.#{$prefix} {
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.5vh;
    width: 100%;
    background-color: $component-background-color;
  }

  &-left {
    height: 100%;
  }

  &-back-button {
    display: inline-block;
    height: 100%;
    padding: 0 0 0 0.5rem;
    border: none;
    background-color: transparent;
    transition: 0.2s;
    outline: none;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }

  &-location {
    display: inline-block;
    height: 100%;
    font-weight: bold;
    line-height: 4vh;
    vertical-align: middle;
  }

  &-right {
    padding: 2rem;
  }

  &-user {
    cursor: pointer;
  }

  &-link-item {
    color: $text-color;
  }
}
