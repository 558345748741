@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-images-box{
    position: absolute;
    top: 0;
    //height: 100%;
  }
  &-camerabox-top{
    padding-left: 0.5rem;

  }
  &-camerabox-items {
    padding: 0;
    margin: 0;
    padding: 0.25rem;
    overflow: hidden;
    li{
      position: relative;
      float: left;
      list-style-type: none;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
      margin: 0.25rem;
      border:2px solid #666;
      width: 172px;
      height: 109px;
    }
    .camerabox-active{
      border-color:#FF1493
    }
  }
  &-camerabox-tabs {
    padding: 0;
    padding-left: 0.5rem;
    margin: 0;
    font-size: 14px;
    span{
      display: inline-block;
      box-sizing: border-box;
      background: #6f6f6f;
      line-height: 33px;
      height: 35px;
      color: #fff;
      cursor: pointer;
      padding-left: 1rem;
      padding-right: 1rem;
      border-right:1px solid #ddd
    }
    .tab-active{
      background: #444;
      position: relative;
    }
    .tab-active:after{
      content:'';
      background: #444;
      display:block;
      height: 10px;
      width:10px;
      position: absolute;
      top: 0px;
      left:50%;
      transform:translateY(-4.24264069px) rotate(45deg)
    }
    .exitBorder{
     border-bottom: 3px solid #F21194;
    }
    span:last-child{
      border-right:none
    }
  }
}
