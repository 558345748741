@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-labels-init-attributes {
    position:absolute;
    width: 236px;
    left: 50%;
    transform: translateX(-50%);
    top: 2.1rem;
    background: opacify($color: #000000, $amount: 0.4);
    padding: 0.5rem;
  }
  &-attributes-row {
    display: flex;
    margin: 0.4rem 0;
  }

  &-label-head {
    display: inline-block;
    width: 2.6rem;
  }

  &-label-head-plhd {
    display: block;
    font-size: 0.6rem;
    user-select: none;

    &::before {
      content: 'x';
      visibility: hidden;
    }
  }

  &-label-content {
    flex: 1;
  }

  &-label-content-row {
    display: flex;
    padding-bottom: 0.1rem;
  }

  &-label-content-mark {
    flex: 1;
    font-size: 0.6rem;
    color: $text-color-title;
    white-space: nowrap;
    user-select: none;
    text-align: center;
  }
}
