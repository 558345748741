@import '../variables.scss';

$prefix: 'admin';

.#{$prefix} {
  & {
    flex: 1;
    padding: 2rem;
    overflow: auto;
  }

  &-panel {
    flex: 1;
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
  }

  &-card-container {
    flex: 1;
    padding-right: 2rem;

    &:last-child {
      padding-right: 0;
    }
  }

  &-card-body {
    display: flex;
    justify-content: space-between;
  }

  &-statistic-container {
    flex: 1;
  }

  &-card-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
