@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-label-relation{
    height: 100%;
  }
  &-relation-lists{
    height: 100%;
    position: relative;
    overflow: auto;
  }
  &-label-box,&-rect-box {
    display: flex;
    width: 50%;
  }
  &-label-box {
    justify-content:flex-end;
  }
  &-label-rect{
    position: relative;
    display: flex;
    .label-type{
      margin:0 0.5rem;
      height:90%;
      margin-top: 0.3rem;
    }
  }
  &-rect-link{
    position: absolute;
    left:49%;
    top:0px;
    transform: rotate(43deg);
  }
  &-relation-button{
    text-align: center;
    margin-bottom: 8px;
    button{
      width: 50%;
      line-height: 37px;
      height: 37px;
    }
  }
}
