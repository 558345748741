@import './variables.scss';

body {
  margin: 0;
  padding: 0;
  background-color: $body-background-color;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $component-background-color-light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas:focus {
  outline: none;
}

// Ant global override styles
.ant-input-password-icon {
  &:hover {
    color: $text-color-secondary;
  }
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

.ant-table-tbody {
  background-color: $component-background-color;
}

.ant-table-small {
  background-color: $component-background-color;
}

.ant-calendar-picker {
  width: 100%;
}

.ant-table-header-column {
  width: 100%;
}

.ant-table-placeholder {
  background: $component-background-color;
}

.ant-table-filter-dropdown {
  background-color: $component-background-color;
}

// Tabs
.ant-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ant-tabs-card .ant-tabs-content {
  flex: 1;
  display: flex;
  height: 100%;
}

.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 0 1rem;
  border-bottom: 1px solid $component-background-color-light;
}

.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane-active {
  height: 100%;
  overflow: auto;
}

.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane-inactive {
  width: 0;
}

.ant-tabs-card .ant-tabs-bar {
  border-color: transparent;
  background-color: $component-background-color-light;
}

.layout-main .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  background: transparent;
  border-left: none;
  border-right: none;
  border-bottom: none;
  user-select: none;
}

.layout-main .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: $component-background-color-light;
  background: $component-background-color;
  color: #e1e1e1;
  font-weight: bold;
}

// Self-defined antd tabpane wrapper
.ant-tabpane-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.ant-legacy-form-item-label > label {
  color: #fff !important;
}
.has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover{
  background-color: #333 !important;
}
.ant-table-cell-fix-left, .ant-table-cell-fix-right,.ant-table-small .ant-table-thead > tr > th{
  background: inherit !important;;
}
.ant-table.ant-table-small{
  font-size: 13px;
}
.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td{
  padding-left:5px;
  padding-right:5px
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled),.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title{
  background-color: #4a4a4a;
}