@import '../variables.scss';

$prefix: 'annot';
.ant-table-filter-dropdown{
  position: relative;
  top: -7rem;
}
.#{$prefix} {
  & {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    gap: 1.5rem;
    overflow: auto;
  }

  // .ant-dropdown{
  //   position: relative;
  //   top: -5rem;
  // }

  &-filter {
    grid-column: 1/2;
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.7rem 1.2rem;
    place-items: center start;
    padding: 1rem;
    background-color: $component-background-color;

    &-title {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 0.8rem;
    }

    &-status {
      display: inline-grid;
      grid-template-columns: 25rem auto auto;
      gap: 0 1rem;
      align-items: center;
    }

    &-tip {
      color: $text-color-title;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  &-stats {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: repeat(3, auto);
    padding: 1rem;
    background-color: $component-background-color;
  }

  &-table {
    grid-column: 1/3;
    align-self: start;
    gap: 1rem;
    padding: 1rem;
    background-color: $component-background-color;
    overflow: hidden;

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-topbar {
      margin-bottom: 0.8rem;
    }

    &-actions {
      display: grid;
      grid-auto-flow: column;
      justify-content: end;
      gap: 0.5rem;
    }

    .ant-table-fixed-left table,
    .ant-table-fixed-right table {
      background: $component-background-color;
    }

    
  }


  &-drawer {
    &-actions {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;

      &.one {
        grid-template-columns: 1fr;
      }
    }
  }

  &-stat {
    &-row{
      margin-bottom: 2rem;
    }
  }

  &-edit {
    &-row {
      display: grid;
      grid-template-columns: 5rem 1fr 5rem 1fr;
      gap: 1rem;
    }
    &-arow {
      display: grid;
      grid-template-columns: 5rem 1fr;
      gap: 1rem;
      margin-top: 1rem;
    }
  }

  &-empty {
    padding: 2rem;
    color: $text-color-secondary;
    user-select: none;
  }

  &-unlabeled {
    display: grid;
    gap: 1rem;

    &-chart {
      padding: 0.5rem;
      border: 1px solid $component-background-color-light;
      font-size: $font-size-xs;
    }

    &-range {
      display: grid;
      grid-template-columns: 1fr auto 1fr auto;
      grid-gap: 0.5rem;

      .ant-input-number {
        width: 100%;
      }
    }
  }
}
