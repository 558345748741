@import '../../variables.scss';

$prefix: 'icon-button';

.#{$prefix} {
  & {
    @include button-base;

    &:hover,
    &.active {
      // Put active here for bounceing effect
      background-color: $row-hover-background-color;
    }

    &:active {
      background-color: $row-highlight-background-color;
    }
  }
}
