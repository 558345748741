@import '../../variables.scss';

$prefix: 'layout';

.#{$prefix} {
  & {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  &-main {
    display: flex;
    flex: 1;
    min-height: 0;
  }
}