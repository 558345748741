@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-addlabel-row {
    display: flex;
    position: absolute;
    bottom: 0.5rem;
    right: 50%
  }

  &-label-lists {
    padding: 0;
    margin: 0;
    li{
      position: relative;
      float: left;
      list-style-type: none;
      margin:0px 5px;
      width: 40px;
      height: 40px;
      font-size: 20px;
      text-align: center;
      background: rgba($color: #000000, $alpha: 0.2);
      border:1px solid #000;
      padding: 5px;
      cursor: pointer;
      span{
        color: #999;
        font-size: 12px;
        position: absolute;
        top: 1px;
        left: 1px;
      }
      .anticon{
        font-size: 20px;
        top: 8px;
        left: 9px;
      }
    }
    li:hover{
      border: 3px solid #D4AC02;
      background: rgba($color: #D4AC02, $alpha: 0.2);
    }
    .add-active-car{
      border: 3px solid #3498DB;
      background: rgba($color: #3498DB, $alpha: 0.2);
    }
    .add-active-cone{
      border: 3px solid #02fde3;
      background: rgba($color: #02fde3, $alpha: 0.2);
    }
    .add-active-pedestrian{
      border: 3px solid #148F77;
      background: rgba($color: #148F77, $alpha: 0.2);
    }
    .add-active-truck{
      border: 3px solid #FF1493;
      background: rgba($color: #FF1493, $alpha: 0.2);
    }
    .add-active-bicycle{
      border: 3px solid #fd8d02;
      background: rgba($color: #fd8d02, $alpha: 0.2);
    }
    .add-active-cyclist{
      border: 3px solid #D4AC0D;
      background: rgba($color: #D4AC0D, $alpha: 0.2);
    }
    .add-active-more{
      border: 3px solid #fff;
      background: rgba($color: #fff, $alpha: 0.2);
    }
  }
}
