@import '../variables.scss';

$prefix: 'test';

.#{$prefix} {
  & {
    flex: 1;
    min-height: 0;
    padding: 2rem;
  }

  &-form-container {
    width: 700px;

    @include media('<=s') {
      width: 100%;
    }

    @include media('<=m', '>s') {
      width: 90%;
    }

    @include media('<=l', '>m') {
      width: 80%;
    }
  }
}

.test .ant-input-number {
  width: 100%;
}
