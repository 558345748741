@import '../variables.scss';

$prefix: 'loading';

.#{$prefix} {
  & {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 40vh;
    background-color: $body-background-color;
    text-align: center;
  }
}
