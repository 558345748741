@import '../variables.scss';

$prefix: 'examhome';

.#{$prefix} {
  & {
    flex: 1;
    padding: 2rem;
  }

  &-annotation {
    padding: 1rem;
    background-color: $component-background-color;
  }
}
