@import '../variables.scss';

$prefix: 'login';

.#{$prefix} {
  & {
    display: flex;
    width: 100vw;
    height: 100vh;
  }

  &-left {
    flex: 1;
    background-image: url('../_assets/bk.png');
    background-size: cover;
    overflow: hidden;
  }

  &-right {
    width: 40vw;

    @include media('<=s') {
      width: 100vw;
    }

    @include media('<=m', '>s') {
      width: 70vw;
    }

    @include media('<=l', '>m') {
      width: 50vw;
    }
  }

  &-panel {
    padding: 25vh 5rem;

    @include media('<=s') {
      padding: 25vh 2rem;
    }

    @include media('<=m', '>s') {
      padding: 25vh 3rem;
    }

    @include media('<=l', '>m') {
      padding: 25vh 4rem;
    }
  }

  &-row {
    &:last-child {
      margin-top: 0.5rem;
    }
  }

  &-message {
    padding-left: 1rem;
    min-height: 1.5rem;
    color: $input-warning-color;
    font-size: $font-size-xs;
    user-select: none;
    line-height: 1.1rem;
  }

  &-message span {
    vertical-align: middle;
  }

  &-lang-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: #a0a0a0;
    padding: 1rem 0;
    text-align: right;
  }

  &-text-logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: $text-color;
    user-select: none;
  }
}
