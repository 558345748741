@import '../../../variables.scss';

$prefix: 'views';

.#{$prefix} {
  &-attributes-row {
    display: flex;
    margin: 0.4rem 0;
  }

  &-label-head {
    display: inline-block;
    width: 5rem;
  }

  &-label-head-plhd {
    display: block;
    font-size: 0.6rem;
    user-select: none;

    &::before {
      content: 'x';
      visibility: hidden;
    }
  }

  &-label-content {
    flex: 1;
  }

  &-label-content-row {
    display: flex;
    padding-bottom: 0.1rem;
  }

  &-label-content-mark {
    flex: 1;
    font-size: 0.6rem;
    color: $text-color-title;
    white-space: nowrap;
    user-select: none;
    text-align: center;
  }
}
