@import '../../../variables.scss';

$prefix: 'viewer2';

.#{$prefix} {
  &-labels-stats {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    max-height: 24vh;
    margin: 0;
    padding: 0;
    background-color: transparentize($component-background-color, 0.4);
    font-size: 0.8rem;
    overflow: auto;

    &-item {
      display: flex;
      list-style-type: none;
      margin: 0.2rem 0.6rem;
      
      &:first-of-type {
        margin-top: 0.4rem;
      }

      &:last-of-type {
        margin-bottom: 0.4rem;
      }
    }

    &-type {
      min-width: 7.1rem;
    }
  }
}
