@import '../variables.scss';

$prefix: 'objectTypes';

.#{$prefix} {
  & {
    flex: 1;
    overflow: auto;
  }

  &-filter{
    padding: 1rem;
    margin: 2rem 2rem;
    background-color: $component-background-color;

    &-item{
      margin-right: 2rem;
    }

  }

  &-table-panel {
    margin: 2rem 2rem;
  }

  &-empty {
    padding: 2rem;
    color: $text-color-secondary;
    user-select: none;
  }

  &-table-cell-icon {
    display: inline-block;
    padding-right: 0.4rem;
    transition: 0.3s;
    cursor: pointer;
  }

  &-modal-tail {
    margin: 1.5rem 0;
  }
}
