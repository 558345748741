@import '../variables.scss';

$prefix: 'views';

.#{$prefix} {
  & {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  //* upper panel
  &-top-panel {
    position: relative;
    flex: 1;
    display: flex;
    min-height: 0; // An important setting, see https://blog.csdn.net/qq_35038331/article/details/73527395
  }

  //* left panel
  &-left-panel {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction:column
  }
  &-bottom-list{
    display: flex;
    flex: 1;
    margin: 5px;
  }
  &-list-2d,&-list-3d{
    width: 30%;
    height: 100%;
    position: relative;
    overflow: auto;
  }
  &-list-relation{
    width:40%;
    margin:0px 5px;
    position: relative;
    height: 100%;
  }
  &-top-canvas{
    width: 100%;
    height: 1px;
    display: flex;
  }

  &-main-2d {
    position: relative;
    width: 50%;
  }
  &-statis-counter{
    position: absolute;
    top:0.5rem;
    left:0.5em;
    line-height: 26px;
    border-radius: 10px;
    padding: 0 0.5rem 0 0.5rem;
    background:rgba($color: #666, $alpha: 0.8);
  }
  &-counter-right{
    left: auto;
    right:0.5rem
  }
  &-main-3d {
    position: relative;
    width: 50%;
  }

  //* right panel
  &-right-panel {
    display: flex;
    width: 320px;
  }

  &-panel-inner {
    flex: 1;
    display: flex;
    background-color: $component-background-color;
  }


  //* lower panel
  &-bottom-panel {
    display: flex;
    align-items: center;
    padding: 0.3rem 2rem 0.5rem;
    background-color: $component-background-color;
  }

  &-frames-slider {
    flex: 3;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }

  &-slider-bar {
    flex: 1;
  }

  &-slider-input {
    display: flex;
    align-items: center;

    &-frames {
      padding-left: 0.5rem;
    }
  }

  &-frames-actions {
    display: flex;
    padding: 0 0.5rem;
    text-align: center;
  }

  &-frames-action {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &-label {
    color: $text-color;
    font-size: 0.7rem;
    font-weight: bold;
    padding: 2px 4px;
  }

  // Settings
  &-setting-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.3rem;
  }

  &-setting-title {
    color: $text-color-title;
  }

  &-setting-head {
    width: 6rem;
  }

  &-setting-control {
    flex: 1;
  }

  //* examiner actions
  &-exam-row {
    display: flex;
    margin-bottom: 0.3rem;
  }
  

  &-exam-head {
    display: inline-block;
    width: 5rem;
  }
}

